import React from 'react';

import { Notification } from 'types/Notification';

import NotificationRow from './NotificationRow';

import './NotificationDropdown.css';

interface NotificationDropdownProps {
  notifications: Notification[];
}

export const NotificationDropdown: React.FC<NotificationDropdownProps> = ({ notifications }) => {
  return (
    <div className="notification-dropdown-results" data-testid="notification-dropdown-results">
      {notifications.map(notification => (
        <NotificationRow key={notification.idAsString} notification={notification} />
      ))}
    </div>
  );
};
