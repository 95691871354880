import React from 'react';
import { connect } from 'react-redux';

import Icon from 'components/Icon/Icon';
import { withTranslation } from 'components/Localization/Localisation';
import Modal from 'components/Modal/Modal';
import { dismissError } from 'data/actions/ErrorActions';
import colasLogoSrc from 'images/colas-logo.svg';

import './GlobalErrorModal.css';

const GlobalErrorModal = props => {
  const { t } = props;

  let title = t('error');

  if (props.error && props.error.status && props.error.status.toString().charAt(0) !== '2') {
    title = 'Information';
  }

  return (
    <Modal
      name="global-error"
      visible={!!props.error}
      onClose={() => {
        if (!props.error.final) {
          props.dismissError();
        }
      }}
    >
      <div className="modal-header">
        <p>{title}</p>
        {props.error && !props.error.final && (
          <div className="modal-header-close" onClick={() => props.dismissError()}>
            <Icon name="close" />
          </div>
        )}
      </div>
      <div className="modal-body">
        <img className="error-logo" src={colasLogoSrc} alt="" />
        <p>{props.error ? props.error.message : null}</p>
      </div>
    </Modal>
  );
};

export default connect(
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  function (state) {
    return {
      error: state.error.current,
    };
  },
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  function (dispatch) {
    return {
      dismissError: () => dispatch(dismissError()),
    };
  },
)(withTranslation(GlobalErrorModal));
