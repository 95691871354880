import React from 'react';

import './Loader.css';

/* eslint-disable-next-line prefer-arrow/prefer-arrow-functions, import/no-anonymous-default-export -- FIXME, FIXME */
export default function (props) {
  return (
    <svg data-testid="loader" className="loader" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  );
}
