import { ComponentType, useCallback, useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';

import { useUserLanguageContext } from 'context/UserLanguageContext';

import CZ from './cz.json';
import DE from './de.json';
import DK from './dk.json';
import EN from './en.json';
import ES from './es.json';
import FR from './fr.json';
import HR from './hr.json';
import HU from './hu.json';
import IS from './is.json';
import IT from './it.json';
import NL from './nl.json';
import PL from './pl.json';
import RO from './ro.json';
import RS from './rs.json';
import SK from './sk.json';
import SL from './sl.json';

const local_fra = FR;
const local_eng = EN;
const local_ces = CZ;
const local_deu = DE;
const local_spa = ES;
const local_dan = DK;
const local_hrv = HR;
const local_hun = HU;
const local_ita = IT;
const local_nld = NL;
const local_pol = PL;
const local_ron = RO;
const local_srp = RS;
const local_slv = SL;
const local_slk = SK;
const local_isl = IS;
const local_default = EN;

const wordings = new LocalizedStrings<typeof FR>({
  ENG: {
    ...local_eng,
  },
  FRA: {
    ...local_fra,
  },
  CES: {
    ...local_default,
    ...local_ces,
  },
  DEU: {
    ...local_default,
    ...local_deu,
  },
  SPA: {
    ...local_default,
    ...local_spa,
  },
  DAN: {
    ...local_default,
    ...local_dan,
  },
  HRV: {
    ...local_default,
    ...local_hrv,
  },
  HUN: {
    ...local_default,
    ...local_hun,
  },
  ITA: {
    ...local_default,
    ...local_ita,
  },
  NLD: {
    ...local_default,
    ...local_nld,
  },
  POL: {
    ...local_default,
    ...local_pol,
  },
  RON: {
    ...local_default,
    ...local_ron,
  },
  SRP: {
    ...local_default,
    ...local_srp,
  },
  SLV: {
    ...local_default,
    ...local_slv,
  },
  SLK: {
    ...local_default,
    ...local_slk,
  },
  ISL: {
    ...local_default,
    ...local_isl,
  },
});

export type WordingsKeys = keyof typeof FR;

interface TranslationProps {
  t: (translationKey: WordingsKeys, placeholdersValues?: Record<string, string>) => string;
}

export const useTranslation = (): TranslationProps => {
  const { userLanguage } = useUserLanguageContext();

  const [_, setStateForRerender] = useState({});

  const rerender = useCallback(() => {
    /**
     * change the value of a pretext state to force a rerender because wordings.setLanguage doesn't trigger any rerender
     * source : https://github.com/stefalda/react-localization#examples
     */
    setStateForRerender({});
  }, []);

  useEffect(() => {
    wordings.setLanguage(userLanguage);
    rerender();
  }, [userLanguage, rerender]);

  const t = useCallback((translationKey: WordingsKeys, placeholdersValues?: Record<string, string>): string => {
    const formattedWording = wordings.formatString(wordings[translationKey], placeholdersValues ?? {});

    if (Array.isArray(formattedWording)) {
      return '';
    }

    return formattedWording;
  }, []);

  return { t };
};

export const withTranslation =
  <T extends TranslationProps>(WrappedComponent: ComponentType<T>): ComponentType<T> =>
  (props: Omit<T, keyof TranslationProps>) => {
    const translationProps = useTranslation();

    // 'as' casting is needed here because of known issue in TS:
    // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
    return <WrappedComponent {...translationProps} {...(props as T)} />;
  };

export default wordings;
