import fetchApi from 'data/fetchApi';

import { displayError } from './ErrorActions';

// Async fetch actions start/end
export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const RECEIVE_EXTERNAL_USER = 'RECEIVE_EXTERNAL_USER';

export const RECEIVE_USER_TOOLS = 'RECEIVE_USER_TOOLS';
export const RECEIVE_USER_TOOLS_ASYNC = 'RECEIVE_USER_TOOLS_ASYNC';
export const RECEIVE_USER_LINKS = 'RECEIVE_USER_LINKS';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestUser() {
  return {
    type: REQUEST_USER,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveUser(userData) {
  return {
    type: RECEIVE_USER,
    user: userData,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveUserTools(tools) {
  return {
    type: RECEIVE_USER_TOOLS,
    tools: tools,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveUserToolsAsync() {
  return {
    type: RECEIVE_USER_TOOLS_ASYNC,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveUserLinks(links) {
  return {
    type: RECEIVE_USER_LINKS,
    links: links,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveExternalUser(userData) {
  return {
    type: RECEIVE_EXTERNAL_USER,
    user: userData,
  };
}

/*

ASYNC ACTIONS

 */

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function fetchExternalUser(token) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    const { uuid, username, firstname, lastname, position, userHash } = token;

    return fetchApi(`/api/users/me/profile`).then(
      responseJson => {
        // compose object with token information and JSON response
        const userData = {
          profile: responseJson,
          uuid,
          username,
          firstname,
          lastname,
          position,
          userHash,
        };

        dispatch(receiveExternalUser(userData));
      },
      rejectionError => {
        rejectionError.final = true;
        dispatch(displayError(rejectionError));
      },
    );
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function fetchUser(token) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    dispatch(requestUser());

    const {
      uuid,
      username,
      roles,
      firstname,
      lastname,
      language,
      position,
      picture,
      isEditor,
      isGlobalEditor,
      userHash,
      publicationScopes,
      countryPublicationScope,
    } = token;

    return fetchApi(`/api/users/me/profile`).then(
      responseJson => {
        // compose object with token information and JSON response
        const userData = {
          profile: responseJson,
          uuid,
          username,
          firstname,
          lastname,
          position,
          language,
          picture,
          roles,
          isEditor,
          isGlobalEditor,
          userHash,
          publicationScopes,
          countryPublicationScope,
        };

        dispatch(receiveUser(userData));
      },
      rejectionError => {
        rejectionError.final = true;
        dispatch(displayError(rejectionError));
      },
    );
  };
}

// TODO limit update body to desired sub-objects
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function updateUser(user) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    const requestBody = {
      tools: user.tools.map(tool => tool['@id']),
      links: user.links,
    };

    // Optimistically dispatch action to say tools have been updated
    dispatch(receiveUserTools(user.tools));
    dispatch(receiveUserLinks(user.links));

    return fetchApi(`/api/users/${user.uuid}`, {
      method: 'PUT',
      body: JSON.stringify(requestBody),
    }).then(
      responseJson => {
        dispatch(receiveUserToolsAsync());

        return responseJson;
      },
      rejectionError => {
        dispatch(displayError(rejectionError));
      },
    );
  };
}
