import React from 'react';

// eslint-disable-next-line no-restricted-imports -- FIXME
import svgUrl from '../../images/icon-sprite.svg';

class IconSprite extends React.Component {
  constructor(props) {
    super(props);
    const body = document.body;

    fetch(svgUrl)
      .then(response => response.text())
      .then(svgEl => {
        const svgInjection = document.createElement('svg');
        body.insertBefore(svgInjection, body.firstChild);
        svgInjection.outerHTML = svgEl;
      });
  }

  render() {
    return null;
  }
}

export default IconSprite;
