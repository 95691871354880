import React from 'react';

const Icon = props => (
  <svg className="icon" width={props.width} height={props.height} style={{ color: props.color }} viewBox="0 0 100 100">
    <use xlinkHref={'#' + props.name}></use>
  </svg>
);

Icon.defaultProps = {
  width: '100%',
  height: '100%',
};

export default Icon;
