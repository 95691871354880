import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';
import React, { MouseEvent as MouseEventReact, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useFetchCollection } from 'components/App/hooks/useFetchCollection';
import { useTranslation } from 'components/Localization/Localisation';
import { NotificationDropdown } from 'components/UserBadge/NotificationDropdown';
import { getUserInitials } from 'components/utils';
import { useTypedSelector } from 'data/hooks';
import { selectUser } from 'data/selectors';
import { Notification } from 'types/Notification';
import { User } from 'types/User';

// eslint-disable-next-line no-restricted-imports -- FIXME

import './UserBadge.css';

type Props = {
  author?: User;
  shouldShowIsEditor?: boolean;
};

const UserBadge: React.FC<Props> = ({ shouldShowIsEditor = true, author }) => {
  const user = useTypedSelector(selectUser);
  const userBadgeClickableRef = useRef<HTMLDivElement>(null);
  const userToDisplay = useMemo(() => author ?? user, [author, user]);

  const [shouldShowNotificationDropdown, setShouldShowNotificationDropdown] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<Notification[]>([]);
  const { data, refetch: fetchNotifications } = useFetchCollection<Notification>({
    url: `/api/users/me/notifications`,
    errorMessage: `Failed to fetch notifications for the user: ${userToDisplay.uuid ?? 'unknown'}`,
    autoFetch: false,
  });

  const openDropdown = useCallback(
    (event: MouseEventReact) => {
      setShouldShowNotificationDropdown(!shouldShowNotificationDropdown);
      event.stopPropagation();
      event.preventDefault();
    },
    [shouldShowNotificationDropdown],
  );

  const closeDropdownCallback = useCallback((event: MouseEvent) => {
    //@ts-expect-error ev.target should be Node
    if (event.target !== null && !userBadgeClickableRef.current?.contains(event.target)) {
      setShouldShowNotificationDropdown(false);
    }
  }, []);

  const userId = userToDisplay.uuid;

  useEffect(() => {
    if (shouldShowNotificationDropdown) {
      window.addEventListener('click', closeDropdownCallback);
    }

    return () => {
      window.removeEventListener('click', closeDropdownCallback);
    };
  }, [closeDropdownCallback, shouldShowNotificationDropdown]);

  useEffect(() => {
    if (userId !== undefined && !shouldShowNotificationDropdown) {
      fetchNotifications();
    }
    // We don't want to watch fetchNotifications changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, shouldShowNotificationDropdown]);

  useEffect(() => {
    if (data !== null) {
      const notificationsNotUndefined = data.filter(notification => notification.notificationType !== 'Undefined');
      setNotifications(notificationsNotUndefined);
      setUnreadNotifications(notificationsNotUndefined.filter(notification => notification.unread));
    }
  }, [data]);

  if (!userToDisplay.uuid) {
    return <UserBadgePlaceholder />;
  }

  const displayName = (userToDisplay?.firstname ?? '') + ' ' + (userToDisplay?.lastname ?? '');

  if (author !== undefined) {
    return (
      <div className="user-badge">
        <UserBadgeAvatar displayName={displayName} userToDisplay={userToDisplay} />
        <UserBadgeDetail
          displayName={displayName}
          shouldShowIsEditor={shouldShowIsEditor}
          userToDisplay={userToDisplay}
        />
      </div>
    );
  }

  return (
    <div className="user-badge-container" ref={userBadgeClickableRef}>
      {/* eslint-disable-next-line react/button-has-type -- FIXME */}
      <button className="user-badge-clickable-area" disabled={notifications.length === 0} onClick={openDropdown}>
        <div className="user-badge">
          <Badge badgeContent={unreadNotifications.length} color="error" overlap="circular">
            <UserBadgeAvatar displayName={displayName} userToDisplay={userToDisplay} />
          </Badge>
          <UserBadgeDetail
            displayName={displayName}
            shouldShowIsEditor={shouldShowIsEditor}
            userToDisplay={userToDisplay}
          />
        </div>
      </button>
      {shouldShowNotificationDropdown && <NotificationDropdown notifications={notifications} />}
    </div>
  );
};

export default UserBadge;

type UserBadgeDetailProps = {
  displayName: string;
  shouldShowIsEditor: boolean;
  userToDisplay: User;
};

const UserBadgeDetail: React.FC<UserBadgeDetailProps> = ({ displayName, shouldShowIsEditor, userToDisplay }) => {
  const { t } = useTranslation();

  return (
    <div className="details">
      <span className="display-name">{displayName}</span>
      <span>{shouldShowIsEditor && userToDisplay.isEditor && t('editor_title')}</span>
    </div>
  );
};

type UserBadgeAvatarProps = {
  displayName: string;
  userToDisplay: User;
};

const UserBadgeAvatar: React.FC<UserBadgeAvatarProps> = ({ displayName, userToDisplay }) => {
  const [userPicture, setUserPicture] = useState(userToDisplay.picture);

  return userPicture ? (
    <img
      className="avatar"
      src={userPicture}
      alt={displayName}
      onError={() => {
        setUserPicture(null);
      }}
    />
  ) : (
    <span className="avatar empty">
      <p>
        {getUserInitials(userToDisplay.firstname, userToDisplay.lastname) || <FontAwesomeIcon icon={['fas', 'user']} />}
      </p>
    </span>
  );
};

const UserBadgePlaceholder: React.FC = () => (
  <div className="user-badge placeholder">
    <span className="avatar empty"></span>
    <div className="details">
      <span className="display-name"></span>
    </div>
  </div>
);
