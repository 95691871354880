import Color from 'color';
import { Expo, gsap } from 'gsap';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import sniffer from 'sniffer';

// eslint-disable-next-line no-restricted-imports -- FIXME
import Icon from '../../Icon/Icon';

import './Tool.css';

class Tool extends Component {
  constructor(props) {
    super(props);

    this.deleteClickHandler = this.deleteClickHandler.bind(this);
  }

  onMouseEnter = e => {
    if (!this.tile) {
      return;
    }

    gsap.to(this.tile, { duration: 0.5, scale: 0.9, ease: Expo.easeOut });
  };

  onMouseLeave = e => {
    if (!this.tile) {
      return;
    }
    gsap.to(this.tile, { duration: 0.5, scale: 1, ease: Expo.easeOut });
  };

  render() {
    const props = this.props;
    const tool = props.data;

    let toolUrl;

    if (tool.url) {
      toolUrl = tool.url;
    } else {
      toolUrl = sniffer.isDevice ? tool.urlMobile : tool.urlDesktop;
    }

    // Get group color or default color for other tools
    // const toolColor = (tool.group && tool.group.color) ? '#'+tool.group.color : '#CCCCCC';
    let toolColor;

    if (tool.group && tool.group.color) {
      toolColor = '#' + tool.group.color;
    } else if (tool['@type'] === 'CustomTool') {
      toolColor = '#009F4A';
    } else {
      toolColor = '#CCCCCC';
    }

    // tileImage can be vector icon or bitmap
    let tileImage;

    // determine if background is dark or not
    const isLight = Color(toolColor).isLight();

    // First priority is an image url
    if (tool.icon && tool.icon.length > 0) {
      tileImage = <Icon name={tool.icon} width="40%" height="40%" color={isLight ? '#3D3D42' : '#FFFFFF'} />;
    } else {
      tileImage = <span className="initial-icon">{tool.title.charAt(0)}</span>;
    }

    // Display a delete button if needed
    const deleteButton = props.showDeleteButton ? (
      // eslint-disable-next-line react/button-has-type -- FIXME
      <button className="btn tool-delete" onClick={this.deleteClickHandler} title={''}>
        <Icon name="close" color="white" />
      </button>
    ) : null;

    const clickTracking = (e, label) => {
      if (typeof window !== 'undefined') {
        window.gtag('event', 'click', { event_category: 'carrons', event_label: label });
      }
    };

    return (
      <a
        id={tool.icon}
        href={toolUrl}
        className="tool"
        target="_blank"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={e => clickTracking(e, tool.title)}
        rel="noreferrer"
      >
        {deleteButton}

        <div ref={el => (this.tile = el)} className="tool-tile" style={{ backgroundColor: toolColor }}>
          {tileImage}
        </div>

        <div className="tool-details">
          <p className="tool-title">{tool.title}</p>
          <p className="tool-subtitle">{tool.subtitle}</p>
        </div>
      </a>
    );
  }

  deleteClickHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }
}

Tool.propTypes = {
  url: PropTypes.string.isRequired,
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
};

Tool.defaultProps = {
  url: '#',
  showDeleteButton: false,
};

export default Tool;
