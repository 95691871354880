import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useFetchItem } from 'components/App/hooks/useFetchItem';
import { getFrontOfficePath } from 'components/App/utils';
import { InternalNewsDetails } from 'types/InternalNewsDetails';

export const useMarkAsRead = (
  notificationId: string,
): {
  isError: boolean;
  isLoading: boolean;
  markAsRead: () => void;
} => {
  const history = useHistory();

  const onError = useCallback(
    (rejectionError: Record<string, unknown>): void => {
      if (rejectionError.code === 404) {
        history.push(getFrontOfficePath());
      }
    },
    [history],
  );

  const extraOptions = useMemo(
    () => ({
      method: 'PUT',
      body: JSON.stringify({}),
      expectResponse: false,
    }),
    [],
  );

  const { loading, isError, refetch } = useFetchItem<InternalNewsDetails>({
    url: `/api/users/me/notifications/${notificationId}`,
    errorMessage: 'Failed to mark the notification as read',
    errorCallback: onError,
    extraOptions,
    autoFetch: false,
  });

  return { isError, isLoading: loading, markAsRead: refetch };
};
