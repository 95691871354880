import { gsap } from 'gsap';
import differenceBy from 'lodash/differenceBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icon from 'components/Icon/Icon';
import { withTranslation } from 'components/Localization/Localisation';
import AddToolModal from 'components/Toolbox/AddToolModal/AddToolModal';
import Tool from 'components/Toolbox/Tool/Tool';
import { displayError } from 'data/actions/ErrorActions';
import {
  addLinkToUser,
  addToolToUser,
  fetchExternalToolsForUser,
  removeLinkFromUser,
  removeToolFromUser,
} from 'data/actions/ToolActions';

import 'utils/detectMobileDevices';

import './ToolboxExternal.css';

class ToolboxExternal extends Component {
  constructor(props) {
    super(props);
    this.addClickListener = this.addClickListener.bind(this);
    this.editClickHandler = this.editClickHandler.bind(this);
    this.onToolDelete = this.onToolDelete.bind(this);

    this.windowResizeHandler = this.windowResizeHandler.bind(this);

    // Track UI State
    this.state = {
      showAddToolModal: false,
      showDeleteTool: false,
      showDeleteToolModal: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResizeHandler);
    this.startLoadAnimation();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  componentDidUpdate(prevProps) {
    const { t } = this.props;

    // get tools for a valid user
    const userUuid = this.props.user.uuid;

    if (userUuid && userUuid !== prevProps.user.uuid) {
      this.props.getToolData(this.props.user, displayError(new Error(t('home.error.tools'))));
    }

    if (prevProps.ui.loading !== this.props.ui.loading) {
      this.stopLoadingAnimation();
    }
  }

  startLoadAnimation = () => {
    this.tileAnimation = gsap.to(this.fakeTiles, {
      duration: 0.8,
      opacity: 0.35,
      yoyo: true,
      repeat: -1,
      stagger: 0.025,
    });
  };

  stopLoadingAnimation = () => {
    this.tileAnimation && this.tileAnimation.kill();
  };

  render() {
    this.fakeTiles = [];
    const props = this.props;
    const { t } = props;

    this.indispensableTools = [
      {
        '@id': 'external/suite-microsoft',
        '@type': 'IndispensableTool',
        group: {
          '@id': 'external/suite-microsoft',
          '@type': 'GroupTools',
          name: 'Outils collaboratifs',
          color: 'e74125',
        },
        icon: 'office354',
        position: 1,
        restrictedToManager: false,
        subtitle: t('tools.office.subTitle'),
        title: t('tools.office.title'),
        urlDesktop: 'https://www.office.com/?auth=2&home=1',
        urlMobile: 'https://www.office.com/?auth=2&home=1',
      },
      {
        '@id': 'external/moteur-recherche',
        '@type': 'IndispensableTool',
        group: { '@id': 'external/moteur-recherche', '@type': 'GroupTools', name: 'Vie pratique', color: 'd3d3d3' },
        icon: 'google',
        position: 2,
        restrictedToManager: false,
        subtitle: t('tools.google.subTitle'),
        title: t('tools.google.title'),
        urlDesktop: 'https://www.google.fr',
        urlMobile: 'https://www.google.fr',
      },
      {
        '@id': 'external/support-informatique',
        '@type': 'IndispensableTool',
        group: {
          '@id': 'external/support-informatique',
          '@type': 'GroupTools',
          name: 'Vie pratique',
          color: 'ff6d0d',
        },
        icon: 'iteam',
        position: 3,
        restrictedToManager: false,
        subtitle: t('tools.support.subTitle'),
        title: t('tools.support.title'),
        urlDesktop: 'https://colas.service-now.com/sp',
        urlMobile: 'https://colas.service-now.com/sp',
      },
      {
        '@id': 'external/colas',
        '@type': 'IndispensableTool',
        group: { '@id': 'external/colas', '@type': 'GroupTools', name: 'Communication', color: 'ffeb00' },
        icon: 'colas',
        position: 4,
        restrictedToManager: false,
        subtitle: t('tools.colas.subTitle'),
        title: t('tools.colas.title'),
        urlDesktop: 'https://www.colas.com/',
        urlMobile: 'https://www.colas.com/',
      },
    ];

    // filter out tools already added by the user
    const availableCustomTools = differenceBy(props.customTools, props.userTools, '@id');

    const isMobile = window.detectMobileDevices();

    const userTools = isMobile ? props.userTools.filter(tool => tool.isAdaptive) : this.props.userTools;

    // Create list of placeholder if loading or display tools
    let toolList;
    if (props.ui.loading) {
      toolList = (
        <div className="frontoffice-tool-list empty">
          {[...Array(7)].map((v, i) => (
            <div ref={el => el && this.fakeTiles.push(el)} key={i} className="tool placeholder">
              <div className="tool-tile" />
              <div className="tool-details">
                <p className="tool-title">&nbsp;</p>
                <p className="tool-subtitle">&nbsp;</p>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      toolList = (
        <div className="frontoffice-tool-list">
          {this.indispensableTools.map(tool => (
            <Tool key={tool['@id']} data={tool} showDeleteButton={false} />
          ))}

          {userTools.map(tool => (
            <Tool
              key={tool['@id']}
              data={tool}
              showDeleteButton={this.state.showDeleteTool}
              onDelete={() => this.onToolDelete(tool)}
            />
          ))}

          {props.userLinks.map(link => (
            <Tool
              key={link['@id']}
              data={link}
              showDeleteButton={this.state.showDeleteTool}
              onDelete={() => this.onLinkDelete(link)}
            />
          ))}

          {/*Add tool button*/}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME */}
          <a className="tool tool-add" onClick={this.addClickListener}>
            <div className="tool-tile">
              <Icon name="plus" width="36%" height="36%" />
            </div>
          </a>
        </div>
      );
    }

    return (
      <div className="portal-section toolbox-external">
        <h3 className="portal-section-title">
          {t('home.tools.title')}
          {!this.props.ui.loading ? (
            <div className="extra-items">
              {/* eslint-disable-next-line react/button-has-type -- FIXME */}
              <button className="portal-section-title-action" onClick={this.editClickHandler}>
                {this.state.showDeleteTool ? t('home.tools.close') : t('home.tools.edit')}
              </button>
            </div>
          ) : (
            <div className="loading-indicator">
              <span>{t('home.tools.loading')}</span>
            </div>
          )}
        </h3>
        <div className="portal-section-content">{toolList}</div>

        <AddToolModal
          visible={this.state.showAddToolModal}
          onClose={() => this.setState({ showAddToolModal: false })}
          tools={availableCustomTools}
          onToolAdd={tool => this.onToolAdd(tool)}
          onLinkAdd={link => this.onLinkAdd(link)}
          userIsUpdating={this.props.userIsUpdating}
        />
      </div>
    );
  }

  onToolAdd(tool) {
    this.props.addToolToUser(this.props.user, tool);
  }

  onToolDelete(tool) {
    this.props.removeToolFromUser(this.props.user, tool);
  }

  onLinkDelete(link) {
    this.props.removeLinkFromUser(this.props.user, link);
  }

  onLinkAdd(link) {
    return this.props.addLinkToUser(this.props.user, link);
  }

  editClickHandler() {
    this.setState({
      showDeleteTool: !this.state.showDeleteTool,
    });
  }

  addClickListener(event) {
    this.setState({
      showAddToolModal: true,
      showDeleteTool: false,
    });
  }

  /**
   * We need to force re-render on window resize to make sur tool urls are updated
   * @param event
   */
  windowResizeHandler(event) {
    this.forceUpdate();
  }
}

ToolboxExternal.propTypes = {
  onToolAdd: PropTypes.func,
  onToolDelete: PropTypes.func,
};

export default connect(
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  function (state) {
    return {
      ui: state.ui.tools,
      userIsUpdating: state.ui.user.loading,
      user: state.user,
      customTools: state.tools.custom,
      userTools: state.user.tools,
      userLinks: state.user.links,
    };
  },
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  function (dispatch) {
    return {
      getToolData: (user, onError) => dispatch(fetchExternalToolsForUser(user, onError)),
      addToolToUser: (user, tool) => dispatch(addToolToUser(tool)),
      removeToolFromUser: (user, tool) => dispatch(removeToolFromUser(tool)),
      addLinkToUser: (user, link) => dispatch(addLinkToUser(link)),
      removeLinkFromUser: (user, link) => dispatch(removeLinkFromUser(link)),
    };
  },
)(withTranslation(ToolboxExternal));
