import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { useMarkAsRead } from 'components/UserBadge/hooks/useMarkAsRead';
import { Notification } from 'types/Notification';
import { HTML_TAGS } from 'utils/constants';
import { useDisplayShortDate } from 'utils/date';
import { sanitizeContentForDisplay } from 'utils/sanitize';

import './NotificationRow.css';

type Props = {
  notification: Notification;
};

const NotificationRow: React.FC<Props> = ({ notification }) => {
  const notificationDate = useDisplayShortDate(notification.date);
  const { markAsRead } = useMarkAsRead(notification.idAsString);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (notification.unread && notification.notificationType === 'Alert' && inView) {
      markAsRead();
    }
  }, [inView, markAsRead, notification.notificationType, notification.unread]);

  return (
    <div className={'notification-row-container'} data-testid="notification-result-row" ref={ref}>
      {notification.notificationType === 'ValidationRequest' &&
      notification.url !== undefined &&
      notification.url !== null ? (
        <div className="clickable-notification">
          <a href={notification.url} target="_blank" rel="noreferrer" onClick={markAsRead}>
            <NotificationContent notification={notification} date={notificationDate} />
          </a>
        </div>
      ) : (
        <NotificationContent notification={notification} date={notificationDate} />
      )}
    </div>
  );
};

export default NotificationRow;

type NotificationContentProps = {
  notification: Notification;
  date: string;
};

const NotificationContent: React.FC<NotificationContentProps> = ({ notification, date }) => {
  return (
    <div className="notification-content">
      <div className="notification-details">
        <p className="notification-dot">{notification.unread && <FontAwesomeIcon icon={['fas', 'circle']} />}</p>

        <p
          className="notification-text"
          dangerouslySetInnerHTML={{
            __html: sanitizeContentForDisplay(notification.message).replace(HTML_TAGS, ''),
          }}
        />
      </div>

      <p className={'notification-date'}>{date}</p>
    </div>
  );
};
